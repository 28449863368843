import { useGet, useErrorHandler, useHairdresser } from '@hooks';
import { CommissionTransaction } from '@models';
import { format, addDays, endOfDay, endOfMonth, endOfYear } from 'date-fns';

export const useEmployeeProductSale = (year: number, month?: number, day?: number) => {
  const { employee } = useHairdresser();

  if (!year || !employee) return {
    transactions: null,
    isLoading: false,
    error: "Missing required data",
  };

  let startDate = new Date(year, 0, 1);
  let endDate = endOfYear(startDate);

  if (month && day && day > 100) {
    // Month, and a week of days are selected
    startDate = new Date(year, month - 1, day - 100);
    endDate = addDays(startDate, 6);
  } else if (month && day) {
    // Month and a day are selected
    startDate = new Date(year, month - 1, day);
    endDate = startDate;
  } else if (month) {
    // Only a month is selected
    startDate = new Date(year, month - 1, 1);
    endDate = endOfMonth(startDate);
  }

  let url = `/transactions/commission?employeeId=${employee.id}&from=${format(startDate, "yyyy-MM-dd")}`;
  if (startDate !== endDate) {
    endDate = endOfDay(endDate);
    url += `&to=${format(endDate, 'yyyy-MM-dd\'T\'HH:mm:ss')}`;
  };

  let { data, error } = useGet<CommissionTransaction[]>(url);
  useErrorHandler(data, error, 'failedToFetchEmployeeStats');

  return {
    transactions: data,
    isLoading: !error && !data,
    error: error,
  };
};
