import { useSanity } from 'hooks';

export const useSystemAlerts = (locale = 'en') => {
  const { data, error } = useSanity<SystemAlert>(`
    *[_type == 'hairdresser_alert' && active == true] {
      "severity": severity,
      "title": title.${locale},
      "message": message.${locale}
    }[0]
  `, locale, 1000 * 60 * 5); // 5 minutes

  return {
    systemAlerts: data,
    isLoading: !error && !data,
    error: error,
  };
};
