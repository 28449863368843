import { sanityClient } from 'lib/sanity.server';
import useSWR from 'swr';

const sanityFetcher = async (query: string, locale: string, refreshInterval: number | undefined = undefined) => {
  return sanityClient.fetch(query, { locale }, refreshInterval ? {
    next: { revalidate: (refreshInterval - 1) / 1000 },
  }: undefined);
};

export const useSanity = <Data>(query: string, locale = 'nb', refreshInterval: number | undefined = undefined) => {
  return useSWR<Data>([query, locale, refreshInterval], sanityFetcher, {
    revalidateOnFocus: false,
    refreshInterval,
  });
};
